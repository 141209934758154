import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";
import appConfig from "../../app.config";
import RoleMapper from "@/utils/RoleMapper";
import { useAuthStore } from "@/store/auth/authStore";

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();

  const roles = auth.user?.roles?.map((role) => RoleMapper(role)) || []; // Ensure it defaults to an empty array

  if (to.path === "/") {
    if (roles.includes("Admin")) {
      return next({ name: "admin.dashboard" });
    }
    if (roles.includes("Employee")) {
      return next({ name: "employee.dashboard" });
    }

    return next({ name: "login" });
  }

  if (to.meta.authRequired) {
    if (!auth.isLoggedIn) {
      return next({ name: "login" });
    }

    if (to.meta.roles && !to.meta.roles.some((r) => roles.includes(r))) {
      return next({ name: "not-authorized" });
    }
  }
  // If the route is for guests only and the user is logged in, redirect to the appropriate dashboard
  else if (to.meta.guestOnly && auth.isLoggedIn) {
    if (roles.includes("Admin")) {
      return next({ name: "admin.dashboard" });
    }
    if (roles.includes("Employee")) {
      return next({ name: "employee.dashboard" });
    }
  }

  next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args?.length) {
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }
  document.title = routeTo.meta.title + " | " + appConfig.title;
  next();
});

export default router;
