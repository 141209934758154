import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import store from "./state/store";
import jszip from "jszip";
import persistedStatePlugin from "pinia-plugin-persistedstate";

import pdfmake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import DataTable from "datatables.net-vue3";
import DataTablesLib from "datatables.net-bs5";
import BootstrapVueNext from "bootstrap-vue-next";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import VueFeather from "vue-feather";
import VueGoogleMaps from "@fawmi/vue-google-maps";

import { vMaska } from "maska";
import { VueQueryPlugin } from "@tanstack/vue-query";

import "@/assets/scss/config/default/app.scss";
import "leaflet/dist/leaflet.css";
import "@/assets/scss/mermaid.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "@/assets/scss/custom.scss";
import "aos/dist/aos.css";
import "datatables.net-bs5";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-rowreorder";
import "datatables.net-colreorder";

// import "datatables.net-bs5/css/dataTables.bootstrap5.css";
// import "datatables.net-buttons-bs5/css/buttons.bootstrap5.css";
// import "datatables.net-rowreorder-dt/css/rowReorder.dataTables.css";

DataTable.use(DataTablesLib);
window.JSZip = jszip;
window.pdfMake = pdfmake;
window.pdfMake.vfs = vfsFonts.pdfMake.vfs;
const pinia = createPinia();
pinia.use(persistedStatePlugin);

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

createApp(App)
  .use(VueQueryPlugin)
  .use(store)
  .use(pinia)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVueNext)
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyDUiC49n0UCoKfiz4TrHQwK-BCsLzc_LY4",
      libraries: "places",
    },
  })
  .component(VueFeather.type, VueFeather)
  .directive("maska", vMaska)
  .use(vClickOutside)
  .mount("#app");
