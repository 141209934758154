import { useAuthStore } from "@/store/auth/authStore";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/login"),
    meta: {
      title: "Login",
      authRequired: false,
      guestOnly: true,
    },
  },
  {
    path: "/ui/tabs",
    name: "tabs",
    meta: { title: "Tabs", authRequired: true },
    component: () => import("@/views/ui/tabs"),
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("@/views/auth/forgot-password"),
    meta: {
      title: "Forgot Password",
      authRequired: false,
      guestOnly: true,
    },
  },
  {
    path: "/not-authorized",
    name: "not-authorized",
    component: () => import("@/views/auth/not-authorized"),
    meta: {
      title: "Not Authorized",
      authRequired: false,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/auth/profile"),
    meta: {
      title: "Profile",
      authRequired: true,
    },
  },

  /*** EMPLOYEE ROUTES ***/
  {
    path: "/employee/dashboard",
    name: "employee.dashboard",
    component: () => import("@/views/employee/dashboard"),
    meta: {
      title: "Employee Dashboard",
      authRequired: true,
      roles: ["Employee"],
    },
  },
  /*** END OF EMPLOYEE ROUTES ***/

  /*** ADMIN ROUTES ***/

  {
    path: "/admin/dashboard",
    name: "admin.dashboard",
    meta: {
      title: "Admin Dashboard",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/dashboard"),
  },

  {
    path: "/admin/operation",
    name: "admin.operation",
    meta: {
      title: "Operation",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/operation/operation/index.vue"),
  },

  {
    path: "/admin/operation/schedule",
    name: "admin.operation.schedule",
    meta: {
      title: "Operation Schedule",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/operation/schedule/index.vue"),
  },

  {
    path: "/admin/operation/vacant-shift",
    name: "admin.operation.vacant-shift",
    meta: {
      title: "Operation Vacant Shift",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/operation/vacant_shift/index.vue"),
  },

  {
    path: "/admin/timesheet",
    name: "admin.timesheet",
    meta: {
      title: "Timesheet",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/timesheet/timesheet"),
  },

  {
    path: "/admin/timesheet/view",
    name: "admin.timesheet.view",
    meta: {
      title: "Timesheet View",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/timesheet/view"),
  },

  {
    path: "/admin/timesheet/unprocess",
    name: "admin.timesheet.unprocess",
    meta: {
      title: "Timesheet Unprocess",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/timesheet/unprocess"),
  },

  {
    path: "/admin/timesheet/process",
    name: "admin.timesheet.process",
    meta: {
      title: "Timesheet Process",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/timesheet/process"),
  },

  {
    path: "/admin/employee",
    name: "admin.employee.list",
    meta: {
      title: "List Employee",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/employee/employee"),
  },

  {
    path: "/admin/employee/inducted/:id",
    name: "admin.employee.inducted",
    meta: {
      title: "Employee Inducted",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/employee/employee-inducted"),
  },
  {
    path: "/admin/employee/timeoff/:id",
    name: "admin.employee.timeoff",
    meta: {
      title: "Employee Timeoff",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/employee/employee-timeoff"),
  },
  {
    path: "/admin/employee/compliance/:id",
    name: "admin.employee.compliance",
    meta: {
      title: "Employee Compliance",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/employee/employee-compliance"),
  },
  {
    path: "/admin/employee/roles/:id",
    name: "admin.employee.roles",
    meta: {
      title: "Employee Roles",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/employee/employee-roles"),
  },
  {
    path: "/admin/clients",
    name: "admin.clients",
    meta: {
      title: "Clients",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/clients/client"),
  },

  {
    path: "/admin/clients/venue",
    name: "admin.clients.venue",
    meta: {
      title: "Venue",
      authRequired: true,
    },
    component: () => import("@/views/admin/clients/venue"),
  },

  {
    path: "/admin/clients/revenue",
    name: "admin.clients.revenue",
    meta: {
      title: "Client Revenue",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/clients/revenue"),
  },

  {
    path: "/admin/payment/add",
    name: "admin.payment.add",
    meta: {
      title: "Payment Add",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/payment/add/index.vue"),
  },

  {
    path: "/admin/payment/list",
    name: "admin.payment.list",
    meta: {
      title: "Payment List",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/payment/list/index.vue"),
  },

  {
    path: "/admin/report/roster",
    name: "admin.report.roster",
    meta: {
      title: "Roster Report",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/report/roster/index.vue"),
  },

  {
    path: "/admin/report/sign-in",
    name: "admin.report.sign-in",
    meta: {
      title: "Sign In Report",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/report/signin/index.vue"),
  },

  {
    path: "/admin/report/event",
    name: "admin.report.event",
    meta: {
      title: "Event",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/report/event/index.vue"),
  },

  {
    path: "/admin/report/custom",
    name: "admin.report.custom",
    meta: {
      title: "Custom Report",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/report/custom/index.vue"),
  },

  {
    path: "/admin/setting/job-type",
    name: "admin.setting.job-type",
    meta: {
      title: "Job Type",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/setting/job-type"),
  },

  {
    path: "/admin/setting/roster-status",
    name: "admin.setting.roster-status",
    meta: {
      title: "Roster Status",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/setting/roster-status"),
  },

  {
    path: "/admin/setting/uniform",
    name: "admin.setting.uniform",
    meta: {
      title: "Uniform",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/setting/uniform"),
  },
  {
    path: "/admin/message",
    name: "admin.message",
    meta: {
      title: "Message",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/message"),
  },
  {
    path: "/admin/activity/log",
    name: "admin.activity.log",
    meta: {
      title: "Activity Log",
      authRequired: true,
      roles: ["Admin"],
    },
    component: () => import("@/views/admin/activity/log"),
  },

  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        auth.logout();
        next();
      },
    },
    component: () => import("@/views/auth/logout/basic"),
  },
  /*** END OF ADMIN ROUTES ***/
];
